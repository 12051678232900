<ng-container *ngIf="flightInfo">
  <div class="flight-container" [class.flight-disabled]="isDisabled">
    <div class="flight-info">
      <div class="d-inline-flex flex-column align-items-center">
        <div class="flight-route-lg">
          <span [appTooltip]="{airportCode: flightInfo.flightRoute.departureAirportCode, terminalName: flightInfo.flightRoute.departureAirportTerminal}">
            {{flightInfo.flightRoute.departureAirportCode}}
          </span>
          <span class="mr-1 ml-1">→</span>
          <span [appTooltip]="{airportCode: flightInfo.flightRoute.arrivalAirportCode, terminalName: flightInfo.flightRoute.arrivalAirportTerminal}">
            {{flightInfo.flightRoute.arrivalAirportCode}}
          </span>
        </div>
        <div *ngIf="flightInfo.duration" class="duration">{{flightInfo.duration}}</div>
      </div>
    </div>
    <div class="segment-wrapper">
      <ng-container *ngFor="let segment of flightInfo.segmentsInfo; last as last">
        <div class="segment-container" [ngClass]="last ? 'mb-1' : segment.segmentDuration ? 'mb-3' : 'mb-4'">
          <div class="left-side">
            <div class="route">
              <span [appTooltip]="{airportCode: segment.segmentRoute.departureAirportCode, terminalName: flightInfo.flightRoute.departureAirportTerminal}">
                {{segment.segmentRoute.departureAirportCode}}
              </span>
              <span>→</span>
              <span [appTooltip]="{airportCode: segment.segmentRoute.arrivalAirportCode, terminalName: flightInfo.flightRoute.arrivalAirportTerminal}">
                {{segment.segmentRoute.arrivalAirportCode}}
              </span>
            </div>
            <div class="flight-number stretch-wrapper">{{segment.operatingCarrierFlightNumber | formatFlightNumber}} <span class="stretch"></span></div>
            <span class="material-icons fs-2">
              circle
            </span>
            <div *ngIf="segment.operatingCarrierAirlineID"
                 class="d-flex align-items-center gap-10 cursor-default"
                 popoverClass="operated-by-popover"
                 [ngbPopover]="operatedByPopover"
                 [triggers]="'hover'"
                 placement="top"
                 container="body">
              <div class="operated-by stretch-wrapper">
                Operated by: {{segment.operatingCarrierAirlineID}}
                <span class="stretch"></span>
              </div>
              <app-airline-img
                [airlineID]="segment.operatingCarrierAirlineID"
                [aircraftCode]="segment.aircraftCode"
                [title]="segment.airlineImageDescription"
              ></app-airline-img>
              <app-aircraft-icon
                [aircraftCode]="segment.equipment.aircraftCode">
              </app-aircraft-icon>
            </div>

            <ng-template #operatedByPopover>
              <div>
                <div *ngIf="segment.operatingCarrierFlightNumber"
                     class="d-flex justify-content-between gap-20"
                     [class.mb-2]="segment.equipment.aircraftCode">
                  <span>Operating flight number</span>
                  <span class="black-color">{{segment.operatingCarrierFlightNumber}}</span>
                </div>
                <div *ngIf="segment.equipment.aircraftCode"
                     class="d-flex justify-content-between gap-20">
                  <span>Aircraft</span>
                  <span class="black-color">{{segment.equipment.aircraftCode}}</span>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="right-side">
            <div class="d-flex gap-3">
              {{(segment.departureDate | dateFormat) | async}}
              <ng-container *ngIf="segment.arrivalDate">
                <span>→</span>
                <span class="red-color">{{(segment.arrivalDate | dateFormat) | async}}</span>
              </ng-container>
              <div>
                <div class="ml-2" [style.display]="segment.arrivalDate ? 'inline-grid' : ''">
                  {{segment.flightTime}}
                </div>
                <div class="duration" *ngIf="segment.segmentDuration">
                  {{segment.segmentDuration}}
                </div>
              </div>
            </div>
            <div *ngIf="segment.stopLocations.length" class="pt-1">
              <div
                class="stops"
                [ngbPopover]="stopsInfo"
                placement="top"
                [triggers]="'hover'"
              >
                Stops ({{segment.stopLocations.length}})
              </div>
              <ng-template #stopsInfo>
                <div *ngFor="let stop of segment.stopLocations">
                  <div class="d-flex flex-column align-items-center">
                    <span class="font-weight-bold">{{stop.airportCode}}</span>
                    <span *ngIf="stop.arrivalDate === stop.departureDate; else diffDays">{{(stop.arrivalDate | dateFormat) | async}}</span>
                    <ng-template #diffDays>{{(stop.arrivalDate | dateFormat) | async}}-{{(stop.departureDate | dateFormat) | async}}</ng-template>
                    <span>{{stop.arrivalTime}}-{{stop.departureTime}}</span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showBaggage && showRow" class="baggage-container">
    <app-baggage-allowance-info
      [baggageAllowance]="flightInfo.baggage"
      [passengers]="flightInfo.passengersData"
    ></app-baggage-allowance-info>
  </div>
</ng-container>
